@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fafb !important;
}
.box .heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  color: #111928;
  margin-bottom: 20px;
}
.form-group {
  margin: 10px 0;
}
.form-group .form-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #111928;
}

.form-group .form-input input {
  box-sizing: border-box;
  display: flex;
  height: 44px;
  flex-direction: row;
  align-items: flex-start;
  padding: 13px 20px;
  gap: 10px;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
}
.form-group .form-input fieldset {
  border: none;
}
p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  /* cool-gray/600 */

  color: #4b5563;
}
/* .form-checkbox svg {
  color: #f9fafb;
  border-radius: 4px;
} */
.box .btn-submit {
  background: #1c64f2;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 25px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}
span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #6b7280;
}
a.MuiTypography-root.MuiTypography-p.MuiLink-root.MuiLink-underlineAlways.css-1ps4owl-MuiTypography-root-MuiLink-root {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-decoration: none;
  color: #1c64f2;
}
code {
  font-family: "Inter", sans-serif;
}

.header {
  background: #fff;
  padding: 5px 10px;
  border-bottom: 1px solid #fff;
}
.logo img {
  max-width: 100%;
  height: 60px;
}
.login .leftDiv {
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  margin: auto;
  flex: 1;
}
.login-form {
  padding: 40px;
}
.loginLogo {
  margin: auto;
  padding: 0 15px;
}
.box {
  /* white */
  background: #ffffff;
  /* shadow */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

span.label {
  padding: 5px 10px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.color-green {
  background: rgb(32, 151, 32);
}
.color-red {
  background: #be1717;
}
.MuiTableCell-sizeMedium {
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
}

.autocomplete-select .MuiInputBase-fullWidth {
  padding: 0 !important;
}

#select-active {
  box-sizing: border-box;
  display: flex;
  height: 44px;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 20px;
  gap: 10px;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
}

.button-link {
  background-color: #4caf50 !important; /* Green */
  border: none !important;
  color: white !important;
  padding: 15px 32px !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 16px !important;
  margin: 4px 2px !important;
  cursor: pointer !important;
}
